










import { Vue, Component } from "vue-property-decorator";
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
import aboutmd from "@/views/GetStarted/GetStarted.md";

@Component({
  components: {
    MarkdownItVue,
  },
})
export default class GetStarted extends Vue {
  get content(): string {
    return aboutmd;
  }
}
